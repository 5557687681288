import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`Welcome to `}<strong parentName="p">{`Professionalism for HLT`}</strong>{`!`}</p>
    <p>{`This fully online offering of the 1-credit course will help you prepare for a career beyond academia in the HLT industry.`}</p>
    <h2 {...{
      "id": "important-links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#important-links",
        "aria-label": "important links permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Important Links`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://d2l.arizona.edu",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Course page`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://forum.parsertongue.org",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Forum (by invitation)`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`See the `}<strong parentName="li">{`Getting Started`}</strong>{` section of the course D2L page for an invite link`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/hlt-pro/syllabus.pdf" target="_blank">Syllabus</a>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://calendar.parsertongue.com",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Appointments`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "description",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#description",
        "aria-label": "description permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Description`}</h2>
    <p>{`From the `}<a parentName="p" {...{
        "href": "https://uaccess.schedule.arizona.edu/psp/pubsaprd/UA_CATALOG/HRMS/h/?tab=DEFAULT",
        "target": "_self",
        "rel": "nofollow"
      }}>{`course catalog`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`This course will focus on how to work as a linguist, primarily as an academic one. Topics include how to write abstracts for submission to conferences, grant proposals at the student level, CVs, and job application letters. We will also discuss the academic job application/interview process, negotiations, the tenure process, and academic vs. other careers for linguists.`}</p>
    </blockquote>
    <p>{`We won't be doing any of that.  Instead, this offering of the course focuses on how to find and prepare for a job in the HLT industry.`}</p>
    <h2 {...{
      "id": "course-objectives",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#course-objectives",
        "aria-label": "course objectives permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Course Objectives`}</h2>
    <p>{`In this course, we will . . .`}</p>
    <ul>
      <li parentName="ul">{`describe career goals and develop an actionable plan for growth`}</li>
      <li parentName="ul">{`build out your professional profile and résumé`}</li>
      <li parentName="ul">{`conduct informational interviews and grow our professional network`}</li>
      <li parentName="ul">{`apply to at least one HLT/NLP job`}</li>
      <li parentName="ul">{`craft a résumé aligned with a stated career goal`}</li>
      <li parentName="ul">{`review networking-based approaches to securing interviews`}</li>
      <li parentName="ul">{`prepare for interview loops and hiring screens`}
        <ul parentName="li">
          <li parentName="ul">{`the STAR method for behavioral interviews`}</li>
          <li parentName="ul">{`coding interviews`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "locations-and-times",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#locations-and-times",
        "aria-label": "locations and times permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Locations and Times`}</h2>
    <p>{`This is an `}<strong parentName="p">{`online, asynchronous`}</strong>{` course.  Content is released in a staggered fashion via the course home page.`}</p>
    <ol>
      <li parentName="ol">{`office hours will be held virtually`}</li>
      <li parentName="ol">{`discussions of content will take place in the course forum`}</li>
    </ol>
    <h2 {...{
      "id": "instructor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instructor",
        "aria-label": "instructor permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Instructor`}</h2>
    <p>{`Hi! My name is `}<a parentName="p" {...{
        "href": "/about"
      }}>{`Gus Hahn-Powell`}</a>{`.  I'll be your instructor for this course. `}</p>
    <p>{`I'm a computational linguist interested in ways we can use natural language processing to accelerate scientific discovery by mining millions of scholarly documents.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}><a parentName="th" {...{
              "href": "https://linguistics.arizona.edu/person/gus-hahn-powell",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Gus Hahn-Powell`}</a></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`hahnpowell AT arizona DOT edu`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Office Hours`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`See our course page on D2L`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Appointments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://calendar.parsertongue.com",
              "target": "_self",
              "rel": "nofollow"
            }}>{`https://calendar.parsertongue.com`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "frequently-asked-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#frequently-asked-questions",
        "aria-label": "frequently asked questions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Frequently Asked Questions`}</h2>
    <h3 {...{
      "id": "am-i-eligible-to-take-this-course",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#am-i-eligible-to-take-this-course",
        "aria-label": "am i eligible to take this course permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`Am I eligible to take this course?`}</em></h3>
    <p>{`While any graduate student is welcome to take this course, it will be most beneficial to current and recent HLT or NLP graduate certificate students.`}</p>
    <h3 {...{
      "id": "whats-the-turnaround-time-for-grading-assignments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#whats-the-turnaround-time-for-grading-assignments",
        "aria-label": "whats the turnaround time for grading assignments permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`What's the turnaround time for grading assignments?`}</em></h3>
    <p>{`Normally, we should have assignments graded and posted within a week of the due date.`}</p>
    <h3 {...{
      "id": "what-is-the-policy-on-due-dates-and-late-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-the-policy-on-due-dates-and-late-work",
        "aria-label": "what is the policy on due dates and late work permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`What is the policy on due dates and late work?`}</em></h3>
    <p>{`Make sure that you don't wait until the last minute to start your work. `}<strong parentName="p">{`Late work will not be accepted.`}</strong></p>
    <h3 {...{
      "id": "where-should-i-ask-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#where-should-i-ask-questions",
        "aria-label": "where should i ask questions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`Where should I ask questions?`}</em></h3>
    <p>{`If you have questions about the course, I'd prefer you share them through the course forum.  If it's something you don't want others to see, you can sending me a direct message. `}</p>
    <p>{`If the forum is ever down and you need to reach me about the course, you can send me an email with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[LING 689]`}</code>{` in the subject line (but think of email as a last resort).`}</p>
    <p>{`For planning purposes, please note that your instructor responds to posted questions `}<strong parentName="p">{`Monday & Friday between 9AM–5PM (MST)`}</strong>{`. Typically, you can expect a response within a day.`}</p>
    <h3 {...{
      "id": "where-can-i-find-information-on-what-assignments-are-due-each-week",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#where-can-i-find-information-on-what-assignments-are-due-each-week",
        "aria-label": "where can i find information on what assignments are due each week permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`Where can I find information on what assignments are due each week?`}</em></h3>
    <p>{`From the `}<strong parentName="p">{`Content`}</strong>{` link from the course D2L page, you will find different units referencing the material that you will complete each week of the course. You'll start with the `}<strong parentName="p">{`Start Here`}</strong>{` module before moving on to `}<strong parentName="p">{`Unit 1`}</strong>{`. Due dates will be listed in each unit and in the course calendar.  To keep things predictable, all units have the same general structure.`}</p>
    <p>{`Each unit has links to lessons, videos, readings, and assignments/activities.
Be sure to check out the `}<strong parentName="p">{`Unit Overview`}</strong>{` link for each new unit. `}</p>
    <p>{`The course calendar (accessible from the nav bar) provides a good overview of all due dates. To help avoid missing important deadlines, I recommend that you enter all due dates in the calendar system (ex. Google Calendar, Microsoft Outlook, etc.) you are most comfortable using and set reminders.`}</p>
    <h3 {...{
      "id": "how-do-i-access-my-grades",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-access-my-grades",
        "aria-label": "how do i access my grades permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`How do I access my grades?`}</em></h3>
    <p>{`You will be able to access your grades via the D2L `}<strong parentName="p">{`Grades`}</strong>{` tab.`}</p>
    <h3 {...{
      "id": "how-is-attendance-taken",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-is-attendance-taken",
        "aria-label": "how is attendance taken permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`How is attendance taken?`}</em></h3>
    <p>{`We don't take attendance in this course.`}</p>
    <h3 {...{
      "id": "do-i-need-any-special-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#do-i-need-any-special-software",
        "aria-label": "do i need any special software permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`Do I need any special software?`}</em></h3>
    <p>{`The assignments in this course will be submitted using Git and GitHub. All you need beyond Git and GitHub account is a modern web browser (Firefox, Chrome, Chromium, etc.).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      